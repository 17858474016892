import React, {useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {Icon} from 'react-icons-kit';
import {androidMenu} from 'react-icons-kit/ionicons/androidMenu';
import {androidClose} from 'react-icons-kit/ionicons/androidClose';
import Logo from 'common/components/UIElements/Logo';
import Button from 'common/components/Button';
import Container from 'common/components/UI/ContainerTwo';
import NavbarWrapper, {MenuArea, MobileMenu} from './navbar.style';
import LogoImage from 'common/assets/image/agencyModern/logo150.png';

const Navbar = ({data}) => {

    const [mobileMenu, setMobileMenu] = useState(false);

    const scrollItems = [];

    // data.leftMenuItems.forEach((item) => {
    //     scrollItems.push(item.path.slice(1));
    // });

    const handleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const handleHandleMenuClose = () => {
        setMobileMenu(false);
    };

    return data ? (
        <NavbarWrapper className="agencyModern-navbar navbar">
            <Container>
                <Logo
                    href="/"
                    logoSrc={LogoImage}
                    title="UzOffice24.uz"
                    className="main-logo"
                />
                {/* end of logo */}

                <MenuArea>
                    <ScrollSpyMenu
                        className="menu-items menu-left"
                        menuItems={data.leftMenuItems}
                        offset={-84}
                    />
                    <ScrollSpyMenu
                        className="menu-items menu-right"
                        menuItems={data.rightMenuItems}
                        offset={-84}
                    />
                    {/* end of main menu */}

                    <Button
                        className="menubar"
                        icon={
                            mobileMenu ? (
                                <Icon
                                    style={{color: '#02073E'}}
                                    className="bar"
                                    size={32}
                                    icon={androidClose}
                                />
                            ) : (
                                <Fade>
                                    <Icon
                                        style={{color: '#02073E'}}
                                        className="close"
                                        icon={androidMenu}
                                        size={32}
                                    />
                                </Fade>
                            )
                        }
                        color="#0F2137"
                        variant="textButton"
                        onClick={handleMobileMenu}
                    />
                </MenuArea>
            </Container>

            {/* start mobile menu */}
            <MobileMenu className={`mobile-menu ${mobileMenu ? 'active' : ''}`}>
                <Container>
                    <Scrollspy
                        className="menu"
                        items={scrollItems}
                        offset={-84}
                        currentClassName="active"
                    >
                        {data.mobileMenuItems.map((menu, index) => (
                            <li key={`menu_key${index}`}>
                                <AnchorLink
                                    href={menu.path}
                                    offset={menu.offset}
                                    onClick={handleHandleMenuClose}
                                >
                                    {menu.label}
                                </AnchorLink>
                            </li>
                        ))}
                    </Scrollspy>
                </Container>
            </MobileMenu>
            {/* end of mobile menu */}
        </NavbarWrapper>
    ): <div/>;
};

export default Navbar;
