import React from 'react';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/ContainerTwo';
import BannerWrapper, {
    BannerContent,
    Subscribe,
    Slogan
} from './banner.style';
import {closeModal, openModal} from "@redq/reuse-modal";
import LoginModal from "../../AgencyModern/LoginModal";


const CloseModalButton = () => (
    <Button
        className="modalCloseBtn"
        variant="fab"
        onClick={() => closeModal()}
        icon={<i className="flaticon-plus-symbol"/>}
    />
);

// Authentication modal handler
const handleLoginModal = (data) => {
    openModal({
        config: {
            className: 'login-modal',
            disableDragging: true,
            width: '100%',
            height: '100%',
            animationFrom: {transform: 'translateY(100px)'}, // react-spring <Spring from={}> props value
            animationTo: {transform: 'translateY(0)'}, //  react-spring <Spring to={}> props value
            transition: {
                mass: 1,
                tension: 180,
                friction: 26,
            },
        },
        component: LoginModal,
        componentProps: {data: data},
        closeComponent: CloseModalButton,
        closeOnClickOutside: false,
    });
};

const Banner = ({data}) => {
    return data ? (
        <BannerWrapper id="home">
            <Container>
                <BannerContent>
                    <Heading as="h1" content={data.banner.title}/>
                    <Text className="banner-caption" content={data.banner.body}/>
                    <Subscribe>
                        <Button
                            title={data.banner.buttonChoosePlan}
                            onClick={() => handleLoginModal(data)}
                            type="submit"
                        />
                    </Subscribe>
                    <Slogan>
                        <Heading as="h3" content={data.banner.slogan}/>
                    </Slogan>
                </BannerContent>
            </Container>
        </BannerWrapper>
    ): <div/>;
};

export default Banner;
