import phone from 'common/assets/image/agencyModern/services/phone_70.png';
import mail from 'common/assets/image/agencyModern/services/mail_70.png';
import office from 'common/assets/image/agencyModern/services/3.png';
import featureIcon1 from 'common/assets/image/agencyModern/features/1.png';
import featureIcon2 from 'common/assets/image/agencyModern/features/2.png';
import featureIcon3 from 'common/assets/image/agencyModern/features/3.png';
import featureIcon4 from 'common/assets/image/agencyModern/features/4.png';
import featureIcon5 from 'common/assets/image/agencyModern/features/5.png';
import featureIcon6 from 'common/assets/image/agencyModern/features/6.png';


const dataEn = {
    leftMenuItems: [
        {
            label: 'Home',
            path: '#home',
            offset: '70'
        },
        {
            label: 'Services',
            path: '#services',
            offset: '70'
        },
        {
            label: 'Advantages',
            path: '#features',
            offset: '70'
        }
    ],
    rightMenuItems: [
        {
            label: 'English',
            path: '/',
            staticLink: true,
            offset: '70',
        },
        {
            label: "O'zbek",
            path: '/uz',
            staticLink: true,
            offset: '70'
        },
        {
            label: 'На русском',
            path: '/ru',
            staticLink: true,
            offset: '70'
        },
        {
            label: 'Turkce',
            path: '/tr',
            staticLink: true,
            offset: '70'
        }
    ],
    mobileMenuItems: [
        {
            label: 'Home',
            path: '#home',
            offset: '70'
        },
        {
            label: 'Services',
            path: '#services',
            offset: '70'
        },
        {
            label: 'Advantages',
            path: '#features',
            offset: '70'
        },
        {
            label: 'English',
            path: '/',
            staticLink: true,
            offset: '70',
        },
        {
            label: "O'zbek",
            path: '/uz',
            staticLink: true,
            offset: '70'
        },
        {
            label: 'На русском',
            path: '/ru',
            staticLink: true,
            offset: '70'
        },
        {
            label: 'Turkce',
            path: '/tr',
            staticLink: true,
            offset: '70'
        }
    ],
    features: {
        title: "Advantages",
        titleText: "Convenient & Almost Free",
        items: [
            {
                id: 1,
                icon: featureIcon1,
                title: "Registered Office",
                desc: "Our addresses are certified to be used as Registered Office and Legal Address."
            },
            {
                id: 2,
                icon: featureIcon2,
                title: 'Mail Scan & Forwarding',
                desc: "Incoming mail will be automatically scanned and sent to your email."
            },
            {
                id: 3,
                icon: featureIcon3,
                title: "Dedicated Mailbox",
                desc: "All correspondence will be delivered to your dedicated mailbox."
            },
            {
                id: 4,
                icon: featureIcon4,
                title: 'Local Phone Number',
                desc: "Uzbek phone number will complete your corporate image."
            },
            {
                id: 5,
                icon: featureIcon5,
                title: "Real Street Address",
                desc: "Proof of Address document provided."
            },
            {
                id: 6,
                icon: featureIcon6,
                title: 'Hold Mail Service',
                desc: "All incoming mail will be stored for you in our secure storage."
            }
        ]
    },
    banner: {
        title: "Get a virtual address & phone number in Uzbekistan!",
        body: "Choose your virtual business address from the #1 service. Create a professional business image with your own virtual office address",
        slogan: "Reliable, Simple and Cheap",
        buttonChoosePlan: "Contact Us"
    },
    register: {
        labels: {
            contractNo: "Contract id",
            contractStart: "Start period",
            contractEnd: "End period",
            send: "SEND",
            companyName: "Company name or your name",
            phoneOrEmail: "Email or Phone number"
        },
        willContactYouBack: "Thank you, we have received your inquiry, we will contact you soon!",
        newContract: "New contract",
        prolongContract: "Prolong contract",
        welcomeClient: "Welcome partner",
        welcomeNewClient: "Welcome",
        welcomeNewClientText: "Please fill the following for us to contact you back"
    },
    services: {
        mainTitle: "Your company’s real presence in Uzbekistan",
        mainTitleText: "UzOffice24 - is your full-service virtual office provider in Uzbekistan",
        items: [
            {
                id: 1,
                icon: office,
                title: 'Real Address',
                description: "Certified and prestigious registered office address eligible for registration ",
            },
            {
                id: 2,
                icon: mail,
                title: 'Mail',
                description: "Incoming Mail scanning, translating and forwarding to your email or postal address",
            },
            {
                id: 3,
                icon: phone,
                title: 'Local phone',
                description: "Local Phone Number and Phone Call Answering Service",
            }
        ]
    }
};

const dataUz = {
    leftMenuItems: [
        {
            label: 'Uy',
            path: '#home',
            offset: '70',
        },
        {
            label: 'Servislar',
            path: '#services',
            offset: '70',
        },
        {
            label: 'Imkoniyatlar',
            path: '#features',
            offset: '70',
        },
    ],
    rightMenuItems: [
        {
            label: 'English',
            path: '/',
            staticLink: true,
            offset: '70',
        },
        {
            label: "O'zbek",
            path: '/uz',
            staticLink: true,
            offset: '70',
        },
        {
            label: 'На русском',
            path: '/ru',
            staticLink: true,
            offset: '70',
        },
        {
            label: 'Turkce',
            path: '/tr',
            staticLink: true,
            offset: '70',
        },
    ],
    mobileMenuItems: [
        {
            label: 'Uy',
            path: '#home',
            offset: '70',
        },
        {
            label: 'Servislar',
            path: '#services',
            offset: '70',
        },
        {
            label: 'Imkoniyatlar',
            path: '#features',
            offset: '70',
        },
        {
            label: 'English',
            path: '/',
            staticLink: true,
            offset: '70',
        },
        {
            label: "O'zbek",
            path: '/uz',
            staticLink: true,
            offset: '70',
        },
        {
            label: 'На русском',
            path: '/ru',
            staticLink: true,
            offset: '70',
        },
        {
            label: 'Turkce',
            path: '/tr',
            staticLink: true,
            offset: '70',
        },
    ],
    features: {
        title: "Afzalliklar",
        titleText: "Qulay va deyarli bepul",
        items: [
            {
                id: 1,
                icon: featureIcon1,
                title: "Ro'yxatdan o'tgan idora",
                desc: "Bizning manzillarimiz ro'yxatdan o'tgan idora va yuridik manzil sifatida foydalanish uchun" +
                    " tasdiqlangan."
            },
            {
                id: 2,
                icon: featureIcon2,
                title: 'Pochtani skanerlash va boshqa joyga yo‘naltirish',
                desc: "Kiruvchi xatlar skanerlanadi va elektron pochtangizga yuboriladi.",
            },
            {
                id: 3,
                icon: featureIcon3,
                title: "Maxsus pochta qutisi",
                desc: "Barcha yozishmalar sizning maxsus pochta qutingizga yetkaziladi.",
            },
            {
                id: 4,
                icon: featureIcon4,
                title: 'Mahalliy telefon raqami',
                desc: "O'zbek telefon raqami korporativ imidjingizni to'ldiradi.",
            },
            {
                id: 5,
                icon: featureIcon5,
                title: "Haqiqiy ko'cha manzili",
                desc: "Manzilni tasdiqlovchi hujjat",
            },
            {
                id: 6,
                icon: featureIcon6,
                title: 'Pochtangizni saqlab turish',
                desc: "Barcha kiruvchi xatlar siz uchun bizning xavfsiz omborimizda saqlanadi.",
            },
        ]
    },
    banner: {
        title: "Uzbekistonda virtual adres & telefon raqamiga ega bo'ling!",
        body: "№1 raqamli servisedan virtual biznes manzilingizni tanlang. O'zingizning virtual ofis manzilingiz" +
            " va telefon raqamingiz bilan professional biznes imidjini yarating.",
        slogan: "Ishonchli, Oddiy va Arzon",
        buttonChoosePlan: "Biz bilan bog'laning"

    },
    register: {
        labels: {
            contractNo: "Shartnoma raqami",
            contractStart: "Shartnoma boshi",
            contractEnd: "Shartnoma oxiri",
            send: "YUBORISH",
            companyName: "Korxona nomi yoki ismingiz",
            phoneOrEmail: "Telefon raqami yoki email",
        },
        willContactYouBack: "Rahmat, ma'lumotingiz yuborildi, tez orada siz bilan aloqaga chiqamiz!",
        newContract: "Yangi shartnoma",
        prolongContract: "Shartnomani uzaytirish",
        welcomeClient: "Xush kelibsiz mijoz",
        welcomeNewClient: "Xush kelibsiz",
        welcomeNewClientText: "Biz siz bilan qayta aloqa chiqishimiz uchun quyidagini to'ldiring",
    },
    services: {
        mainTitle: "Sizning kompaniyangizning O'zbekistonda haqiqiy mavjudligi",
        mainTitleText: "UzOffice24 - O'zbekistonda to'liq xizmat ko'rsatadigan virtual ofis provayderingiz",
        items: [
            {
                id: 1,
                icon: office,
                title: 'Nufuzli biznes manzil',
                description: `Ro'yxatdan o'tish uchun tasdiqlangan va nufuzli ofis manzili.`,
            },
            {
                id: 2,
                icon: mail,
                title: 'Pochta ximati',
                description: `Kiruvchi xatlarni skanerlash, tarjima qilish va elektron pochta yoki pochta manzilingizga yo'naltirish`,
            },
            {
                id: 3,
                icon: phone,
                title: 'Telefon xizmati',
                description: `Mahalliy telefon raqami va qo'ng'iroqlarga javob berish xizmati.`,
            }
        ],
    }
};


export const data = {en: dataEn, uz: dataUz};
