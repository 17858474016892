import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import Tabs, {TabPane} from 'rc-tabs';
// import TabContent from 'rc-tabs/lib/TabContent';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Input from 'common/components/Input';
import Button from 'common/components/Button';
import Image from 'common/components/Image';
import LoginModalWrapper from './loginModal.style';
import 'rc-tabs/assets/index.css';
import LoginImage from 'common/assets/image/agency/login-background.jpg';
import {closeModal} from "@redq/reuse-modal";
import axios from "axios";

const LoginModal = ({
                        row,
                        col,
                        btnStyle,
                        logoStyle,
                        titleStyle,
                        contentWrapper,
                        outlineBtnStyle,
                        descriptionStyle,
                        googleButtonStyle,
                        data
                    }) => {

    const defaultState = {
        type: "",
        contract_id: "",
        from_date: "",
        to_date: "",
        company_name: "",
        contact_phone: "",
        contact_email: "",
        comments: ""
    }
    const [query, setQuery] = useState(defaultState);

    const handleChange = (name) => (e) => {
        setQuery((prevState) => ({
            ...prevState,
            [name]: e
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let text = `New request: ${(new Date()).toISOString()}\n`
        Object.entries(query).forEach(([key, value]) => {
            text += `${key}:${value}\n`
        });

        axios.get(
            `https://api.telegram.org/bot1624722463:AAGC3t20XX6Tn4dnjKkPgiQfk5tiKU8rAZ4/sendMessage?chat_id=@testchannel9988&text=${encodeURI(text)}`
        )
            .then(function (response) {
                setQuery(defaultState);
                closeModal();
                alert(data.register.willContactYouBack);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const LoginButtonGroup = () => (
        <Fragment>
            <Button onClick={handleSubmit} className="default" title={data.register.labels.send} {...btnStyle} />
        </Fragment>
    );
    const SignupButtonGroup = () => (
        <Fragment>
            <Button onClick={handleSubmit} className="default" title={data.register.labels.send} {...btnStyle} />
        </Fragment>
    );
    return (
        <LoginModalWrapper>
            <Box className="row" {...row}>
                <Box className="col tabCol" {...col}>
                    <Box {...contentWrapper}>
                        {/*<Image src={LogoImage} {...logoStyle} alt="Logo"/>*/}
                        <Tabs
                            defaultActiveKey="loginForm"
                            animated={{tabPane: true}}
                            // renderTabBar={() => <ScrollableInkTabBar />}
                            // renderTabContent={() => <TabContent />}
                        >
                            <TabPane tab={data.register.newContract} key="loginForm">
                                <Heading content={data.register.welcomeNewClient} {...titleStyle} />
                                <Text content={data.register.welcomeNewClientText} {...descriptionStyle} />
                                <Input onChange={handleChange('company_name')} isMaterial  label={data.register.labels.companyName}/>
                                <Input onChange={handleChange('contact_phone')} isMaterial  label={data.register.labels.phoneOrEmail}/>
                                <div>
                                    <LoginButtonGroup/>
                                </div>
                            </TabPane>
                            <TabPane tab={data.register.prolongContract} key="registerForm">
                                <Heading as='h3' content={data.register.welcomeClient} {...titleStyle} />
                                <Input value='---' onChange={handleChange('contact_id')} isMaterial
                                       label={data.register.labels.contractNo}/>
                                <Input onChange={handleChange('from_date')} value={"---"} inputType="month"
                                       isMaterial
                                       label={data.register.labels.contractStart}/>
                                <Input onChange={handleChange('to_date')} value={"---"} inputType="month" isMaterial
                                       label={data.register.labels.contractEnd}/>
                                <div>
                                    <SignupButtonGroup/>
                                </div>
                            </TabPane>
                        </Tabs>
                    </Box>
                </Box>
                <Box className="col imageCol" {...col}>
                    <Image className="patternImage" src={LoginImage} alt="Login Banner"/>
                </Box>
            </Box>
        </LoginModalWrapper>
    );
};

// LoginModal style props
LoginModal.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    logoStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    hintTextStyle: PropTypes.object,
    contentWrapper: PropTypes.object,
    descriptionStyle: PropTypes.object,
    googleButtonStyle: PropTypes.object,
};

// LoginModal default style
LoginModal.defaultProps = {
    // Team member row default style
    row: {
        flexBox: true,
        flexWrap: 'wrap',
    },
    // Team member col default style
    col: {
        width: [1, 1 / 2],
    },
    // Default logo size
    logoStyle: {
        width: '128px',
        height: 'auto',
        ml: '15px',
    },
    // Title default style
    titleStyle: {
        fontSize: ['22px', '36px', '50px'],
        fontWeight: '400',
        color: '#20201D',
        letterSpacing: '-0.025em',
        mt: '35px',
        mb: '10px',
    },
    // Description default style
    descriptionStyle: {
        color: 'rgba(52, 61, 72, 0.8)',
        fontSize: '15px',
        lineHeight: '26px',
        letterSpacing: '-0.025em',
        mb: '23px',
        ml: '1px',
    },
    // Content wrapper style
    contentWrapper: {
        pt: ['32px', '56px'],
        pl: ['17px', '32px', '38px', '40px', '56px'],
        pr: '32px',
        pb: ['32px', '56px'],
    },
    // Default button style
    btnStyle: {
        minWidth: '156px',
        fontSize: '14px',
        fontWeight: '500',
    },
    // Outline button outline style
    outlineBtnStyle: {
        minWidth: '156px',
        fontSize: '14px',
        fontWeight: '500',
        color: '#10ac84',
    },
    // Google button style
    googleButtonStyle: {
        bg: '#ffffff',
        color: '#343D48',
    },
};

export default LoginModal;
